import { clubbiStyled } from 'clubbi-ui'

export const StyledTitle = clubbiStyled('p')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  marginBottom: '20px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    marginBottom: '0',
  },
}))

export const StyledMain = clubbiStyled('main')(({ theme }) => ({
  padding: '20px 18px',
  backgroundColor: '#ECE6FC',
}))

export const StyledDiv = clubbiStyled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 20px',
  flexWrap: 'wrap',
})

export const StyledCard = clubbiStyled('div')({
  padding: '20px',
})

export const StyledMobile = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'scroll',
}))
