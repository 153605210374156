import { useEffect } from 'react'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { useLocation } from 'react-router-dom'
import useHotjar from 'react-use-hotjar'

import gtm from '../../../common/gtmTracker'
import { useAppSelector } from '../../../common/hooks'
import { HeaderMobile } from '../../molecules/gridHeader/HeaderMobile'
import { HeaderDesktop } from '../../molecules/gridHeader/HeaderDesktop'

import { StyledHeader } from './styles/Header.style'
import { isClubbiAnniversary } from '../../../common/utils'

export const Header = () => {
  const { regionId, merchantCode, loginDatetime, clubberEmail } = useAppSelector(
    (state) => state.session
  )

  const { pathname } = useLocation()

  const { identifyHotjar } = useHotjar()

  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))

  useEffect(() => {
    const timeout = (duration: number) => {
      return new Promise((resolve, _reject) => {
        setTimeout(resolve, duration)
      })
    }

    timeout(3000).then(() => {
      if (merchantCode) {
        identifyHotjar(merchantCode!, {
          login: loginDatetime!,
          region: regionId!,
        })

        gtm.triggerLogin(merchantCode!, loginDatetime!, regionId!, clubberEmail!)
      }
    })
  }, [merchantCode])

  useEffect(() => {
    gtm.triggerPagesView(merchantCode, pathname, regionId, clubberEmail)
  }, [pathname])

  return (
    <StyledHeader isAnniversary={isClubbiAnniversary()}>
      {isDesktop ? <HeaderDesktop /> : <HeaderMobile />}
    </StyledHeader>
  )
}
