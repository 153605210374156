import { formatNumber } from '../../common/utils'
import {
  SyledProfitabilitiesContainer,
  SyledProfitabilitiesDiv,
} from '../organisms/Cart/styles/CartProductsListItem.style'

interface ProfitabilitiesProps {
  props: {
    marginByKg: number
    lc1Percent: number
  }
}

export const ProductProfitabilities = ({ props }: ProfitabilitiesProps) => (
  <>
    {props.marginByKg && props.lc1Percent && (
      <SyledProfitabilitiesContainer>
        <SyledProfitabilitiesDiv isNegative={props.marginByKg < 0}>{`${formatNumber(
          props.marginByKg
        )}/Kg`}</SyledProfitabilitiesDiv>
        <SyledProfitabilitiesDiv
          isNegative={props.lc1Percent < 0}
        >{`${props.lc1Percent}%`}</SyledProfitabilitiesDiv>
      </SyledProfitabilitiesContainer>
    )}
  </>
)
