import React, { useCallback, useState, KeyboardEvent, useEffect } from 'react'
import { ChevronDownIcon, Collapse, themeClubbi } from 'clubbi-ui'

import { SIGN_UP_LINK, CLUBBI_HELP_LINK, MERCHANT_CODE_CHARACTERS } from '../../common/constants'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { loginFromCode, sessionActions } from '../../store/slices/session'

import {
  StyledContainer,
  StyledCloseButton,
  StyledCloseIcon,
  StyledInput,
  StyledModal,
  StyledErrorMessage,
  StyledContainerInput,
  StyledLink,
  StyledButton,
  StyledSpinner,
  StyledLoginText,
  StyledInputEmail,
  StyledMessageEmail,
  StyledDescription,
  StyledClubberMessage,
  StyledContainerClubber,
  StyledText,
} from './styles/LoginModal.style'

export const LoginModal = () => {
  const { merchantCode, showModal, clubberEmail } = useAppSelector((state) => state.session)
  const [merchantInputValue, setMerchantInputValue] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [unregisteredMerchantCode, setUnregisteredMerchantCode] = useState(false)
  const dispatch = useAppDispatch()

  const isMerchantInvalid =
    !!merchantInputValue && merchantInputValue.length != MERCHANT_CODE_CHARACTERS
  const [isClubber, setClubber] = useState(false)
  const [email, setEmail] = useState(clubberEmail)
  const [apiEmailError, setApiEmailError] = useState(false)

  const isValidEmail = !!(email && email.includes('@clubbi.com.br'))

  const blockedLoginButton =
    isMerchantInvalid ||
    unregisteredMerchantCode ||
    !merchantInputValue ||
    !!(email && !isValidEmail)

  const hide = useCallback(() => {
    dispatch(sessionActions.hideModal())
    setMerchantInputValue('')
    setUnregisteredMerchantCode(false)
  }, [dispatch])

  const setShowModal = useCallback(() => dispatch(sessionActions.showModal(true)), [dispatch])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value
      if (inputValue.length <= MERCHANT_CODE_CHARACTERS) setMerchantInputValue(event.target.value)
      setUnregisteredMerchantCode(false)
    },
    [dispatch, setMerchantInputValue]
  )

  const handleChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value
      if (input === '') {
        dispatch(sessionActions.setClubber({ clubber: '' }))
      }
      setEmail(input.toLowerCase())
    },
    [dispatch, setEmail]
  )

  const handleClick = () => {
    const inputMerchantCode = merchantInputValue.toUpperCase()

    if (isValidEmail) {
      dispatch(sessionActions.setClubber({ clubber: email }))
    }

    const loginPromise = dispatch(
      loginFromCode({ merchantCode: inputMerchantCode, clubberEmail: email })
    )
    setLoading(true)

    loginPromise
      .unwrap()
      .then((action) => {
        const [success] = action
        switch (success) {
          case 'OK':
            hide()
            setLoading(false)
            setMerchantInputValue('')
            setUnregisteredMerchantCode(false)
            break
          case 'UNAUTHORIZED_CLUBBER':
            setClubber(true)
            setApiEmailError(true)
            setLoading(false)
            setShowModal()
            break
          default:
            setLoading(false)
            setUnregisteredMerchantCode(true)
            setShowModal()
            break
        }
      })
      .catch(() => setLoading(false))
  }

  const handleKeyboardEvent = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Enter' && !blockedLoginButton) {
      handleClick()
    }
  }

  const handleClickClubber = () => {
    setClubber(!isClubber)
  }

  useEffect(() => {
    if (clubberEmail) {
      setClubber(true)
    }
  }, [])

  return (
    <>
      {!merchantCode && (
        <StyledModal open={showModal} onClose={hide}>
          <StyledContainer>
            <StyledCloseButton data-cy="close-login-modal-button" onClick={hide}>
              <StyledCloseIcon color={themeClubbi.palette.brandGrey.main} />
            </StyledCloseButton>
            <StyledLoginText>Código do cliente</StyledLoginText>
            <StyledDescription>Entre com o código exclusivo para ir às compras!</StyledDescription>
            <StyledContainerInput onKeyDown={handleKeyboardEvent}>
              <StyledInput
                data-cy="login-modal-input"
                placeholder="_ _ _ _ _"
                invalid={isMerchantInvalid || unregisteredMerchantCode}
                onChange={handleChange}
                value={merchantInputValue}
              />
              {isMerchantInvalid && <StyledErrorMessage>Código inválido</StyledErrorMessage>}
              {unregisteredMerchantCode && (
                <StyledErrorMessage>Código não cadastrado</StyledErrorMessage>
              )}
            </StyledContainerInput>
            <StyledClubberMessage data-cy="login-clubber-option" onClick={handleClickClubber}>
              Sou vendedor da Clubbi
              <ChevronDownIcon
                color={themeClubbi.palette.brandPurple[60]}
                rotate={isClubber}
                bold={true}
                fontSize={20}
              />
            </StyledClubberMessage>
            <Collapse
              orientation="vertical"
              in={isClubber}
              collapsedSize={1}
              timeout={150}
              easing={{ enter: 'linear', exit: 'linear' }}
            >
              <StyledContainerClubber onKeyDown={handleKeyboardEvent} isClubber={isClubber}>
                <StyledInputEmail
                  type="email"
                  data-cy="login-modal-input-email"
                  placeholder="Entre com seu usuário interno"
                  invalid={(!isValidEmail && !!email) || apiEmailError}
                  onChange={handleChangeEmail}
                  value={email}
                />

                {isClubber && !email && (
                  <StyledMessageEmail>Exclusivo para uso da Clubbi.</StyledMessageEmail>
                )}
                {isClubber && ((!isValidEmail && !!email) || apiEmailError) && (
                  <StyledMessageEmail>Digite um usuário válido para acessar.</StyledMessageEmail>
                )}
              </StyledContainerClubber>
            </Collapse>
            <StyledButton
              data-cy="login-modal-button"
              onClick={handleClick}
              disabled={blockedLoginButton}
            >
              {isLoading ? <StyledSpinner /> : 'Entrar'}
            </StyledButton>
            <StyledText>
              Não tem conta? <StyledLink href={SIGN_UP_LINK}>Faça seu cadastro grátis</StyledLink>
            </StyledText>
            <StyledText>
              Esqueceu seu código?{' '}
              <StyledLink href={CLUBBI_HELP_LINK}>Fale com a Central de Ajuda</StyledLink>
            </StyledText>
          </StyledContainer>
        </StyledModal>
      )}
    </>
  )
}
