import { apiPost, loadBody } from './api'

export type MerchantData = {
  merchantCode: string
  merchantName: string
  paymentDeadline: string
  regionId?: number
  isInstantPayment?: boolean
  websiteRegionId?: number
  geoRegionId?: number
}

export type LoginData = MerchantData & {
  isClubberEmailValid?: boolean
}

export const identify = async (
  merchantCode: string,
  clubberEmail?: string
): Promise<[string | undefined, LoginData | undefined]> => {
  const response = await apiPost('/identify', { merchantCode, clubberEmail })
  const body = (await loadBody(response)) as LoginData

  if (body.isClubberEmailValid === false) return ['UNAUTHORIZED_CLUBBER', undefined]

  switch (response.status) {
    case 200: {
      return [
        'OK',
        {
          ...body,
          regionId: body.websiteRegionId || body.regionId || 1,
          websiteRegionId: body.websiteRegionId || body.regionId || 1,
          isInstantPayment: (body.isInstantPayment ??= false),
          geoRegionId: body.regionId || 1,
        } as MerchantData,
      ]
    }
    case 401:
      return ['UNAUTHORIZED', undefined]
    case 404:
      return ['NOT_FOUND', undefined]
    default:
      return [undefined, undefined]
  }
}
