import { CartItem } from '../../../store/data/types'
import { useAppSelector } from '../../../common/hooks'
import { CartProductsListItem } from './CartProductsListItem'

import {
  StyledCartCardContainer,
  StyledEmptyCartMessage,
  StyledTitle,
  StyledCartProductsList,
  StyledButton,
  StyledSpinner,
  CartHeader,
} from './styles/CartProductsList.style'
import { cartActions } from '../../../store/slices/cart'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { getProfitabilities } from '../../../services/profitabilityCalculatorApi'

export interface CartProductsListProps {
  items: CartItem[]
}

export const CartProductsList = () => {
  const { cart, session } = useAppSelector((state) => state)
  const cartItems = useAppSelector((state) =>
    Object.values(state.cart).filter((e) => e.quantity > 0)
  )
  const { isInstantPayment, clubberEmail } = useAppSelector((state) => state.session)
  const totalProfitabilities = useAppSelector((state) => state.profitabilities.total)
  const dispatch = useDispatch()

  const [profitabilitiesLoading, setProfitabilitiesLoading] = useState<boolean>(false)
  const [clearPrices, setClearPrices] = useState(false)

  const handleClearAllSimuledPrices = async () => {
    setProfitabilitiesLoading(true)
    dispatch(cartActions.clearAllSimuledPrice())
    setClearPrices(true)
  }

  useEffect(() => {
    if (clearPrices) {
      getProfitabilities(cart, session).finally(() => {
        setProfitabilitiesLoading(false)
        setClearPrices(false)
      })
    }
  }, [clearPrices, cart, session])

  const hasSimuledPrices = Object.entries(cart).some(
    ([, cartItem]) => cartItem.simuledPrice !== undefined
  )

  return (
    <StyledCartCardContainer removeMargin={isInstantPayment ?? false}>
      <CartHeader>
        <StyledTitle>Resumo do Pedido</StyledTitle>
        {clubberEmail && hasSimuledPrices && (
          <StyledButton onClick={handleClearAllSimuledPrices}>
            {profitabilitiesLoading || !totalProfitabilities ? <StyledSpinner /> : 'restaurar'}
          </StyledButton>
        )}
      </CartHeader>
      <StyledCartProductsList>
        {cartItems.map((item) => (
          <CartProductsListItem item={item} key={item.product.id} />
        ))}
      </StyledCartProductsList>
      {cartItems.length == 0 && (
        <StyledEmptyCartMessage>Nenhum produto no carrinho</StyledEmptyCartMessage>
      )}
    </StyledCartCardContainer>
  )
}
