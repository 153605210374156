import { clubbiStyled, Spinner } from 'clubbi-ui'

export const StyledCartCardContainer = clubbiStyled('div')<{ removeMargin: boolean }>(
  ({ theme, removeMargin }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '18px',
    width: '100%',
    borderRadius: '4px',
    marginTop: removeMargin ? '0' : '10px',

    backgroundColor: theme.palette.brandWhite.main,

    [theme.breakpoints.up('md')]: {
      marginTop: removeMargin ? '0' : '25px',
    },
  })
)

export const CartHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',

  [theme.breakpoints.up('md')]: {
    marginBottom: '10px',
  },
}))

export const StyledTitle = clubbiStyled('p')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '109.9%',
  textAlign: 'left',

  color: theme.palette.brandBlack.main,

  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
}))

export const StyledEmptyCartMessage = clubbiStyled('span')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  lineHeight: '109.9%',

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeXXS,
  },
}))

export const StyledCartProductsList = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  [theme.breakpoints.up('md')]: {
    gap: '18px',
  },
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  textTransform: 'capitalize',
  textAlign: 'center',
  fontSize: '10px',
  fontWeight: 600,
  border: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  height: '17.5px',
  display: 'flex',
  alignItems: 'center',

  color: theme.palette.brandWhite.main,
  background: theme.palette.brandAlert[60],

  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
    height: '19.7px',
  },
}))

export const StyledSpinner = clubbiStyled(Spinner)(({ theme }) => ({
  width: '16px !important',
  height: '16px !important',

  color: theme.palette.brandWhite.main,
}))
