import { anniversaryColor } from '../../../common/utils'

export const GiftIcon = () => {
  const color = anniversaryColor.red
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.00012 20.5V11.5C3.45012 11.5 2.97929 11.3042 2.58762 10.9125C2.19596 10.5208 2.00012 10.05 2.00012 9.5V7.5C2.00012 6.95 2.19596 6.47917 2.58762 6.0875C2.97929 5.69583 3.45012 5.5 4.00012 5.5H7.20012C7.11679 5.35 7.06262 5.19167 7.03762 5.025C7.01262 4.85833 7.00012 4.68333 7.00012 4.5C7.00012 3.66667 7.29179 2.95833 7.87512 2.375C8.45846 1.79167 9.16679 1.5 10.0001 1.5C10.3835 1.5 10.7418 1.57083 11.0751 1.7125C11.4085 1.85417 11.7168 2.05 12.0001 2.3C12.2835 2.03333 12.5918 1.83333 12.9251 1.7C13.2585 1.56667 13.6168 1.5 14.0001 1.5C14.8335 1.5 15.5418 1.79167 16.1251 2.375C16.7085 2.95833 17.0001 3.66667 17.0001 4.5C17.0001 4.68333 16.9835 4.85417 16.9501 5.0125C16.9168 5.17083 16.8668 5.33333 16.8001 5.5H20.0001C20.5501 5.5 21.021 5.69583 21.4126 6.0875C21.8043 6.47917 22.0001 6.95 22.0001 7.5V9.5C22.0001 10.05 21.8043 10.5208 21.4126 10.9125C21.021 11.3042 20.5501 11.5 20.0001 11.5V20.5C20.0001 21.05 19.8043 21.5208 19.4126 21.9125C19.021 22.3042 18.5501 22.5 18.0001 22.5H6.00012C5.45012 22.5 4.97929 22.3042 4.58762 21.9125C4.19596 21.5208 4.00012 21.05 4.00012 20.5ZM14.0001 3.5C13.7168 3.5 13.4793 3.59583 13.2876 3.7875C13.096 3.97917 13.0001 4.21667 13.0001 4.5C13.0001 4.78333 13.096 5.02083 13.2876 5.2125C13.4793 5.40417 13.7168 5.5 14.0001 5.5C14.2835 5.5 14.521 5.40417 14.7126 5.2125C14.9043 5.02083 15.0001 4.78333 15.0001 4.5C15.0001 4.21667 14.9043 3.97917 14.7126 3.7875C14.521 3.59583 14.2835 3.5 14.0001 3.5ZM9.00012 4.5C9.00012 4.78333 9.09596 5.02083 9.28762 5.2125C9.47929 5.40417 9.71679 5.5 10.0001 5.5C10.2835 5.5 10.521 5.40417 10.7126 5.2125C10.9043 5.02083 11.0001 4.78333 11.0001 4.5C11.0001 4.21667 10.9043 3.97917 10.7126 3.7875C10.521 3.59583 10.2835 3.5 10.0001 3.5C9.71679 3.5 9.47929 3.59583 9.28762 3.7875C9.09596 3.97917 9.00012 4.21667 9.00012 4.5ZM4.00012 7.5V9.5H11.0001V7.5H4.00012ZM11.0001 20.5V11.5H6.00012V20.5H11.0001ZM13.0001 20.5H18.0001V11.5H13.0001V20.5ZM20.0001 9.5V7.5H13.0001V9.5H20.0001Z"
        fill={color}
      />
    </svg>
  )
}
