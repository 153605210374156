import { useState } from 'react'
import { useAppSelector } from '../../common/hooks'
import { getProfitabilities } from '../../services/profitabilityCalculatorApi'
import { CartButtonDiv, StyledButton, StyledSpinner } from './styles/ButtonProfitabilities.style'

export const ButtonProfitabilities = () => {
  const totalProfitabilities = useAppSelector((state) => state.profitabilities.total)
  const { cart, session } = useAppSelector((state) => state)

  const [profitabilitiesLoading, setProfitabilitiesLoading] = useState<boolean>(false)

  const handleClick = async () => {
    setProfitabilitiesLoading(true)
    await getProfitabilities(cart, session)
    setProfitabilitiesLoading(false)
  }

  return (
    <CartButtonDiv>
      <StyledButton onClick={handleClick}>
        {profitabilitiesLoading || !totalProfitabilities ? (
          <StyledSpinner />
        ) : (
          'Recalcular Rentabilidade'
        )}
      </StyledButton>
    </CartButtonDiv>
  )
}
