import { useDispatch } from 'react-redux'
import { CloseIcon, themeClubbi, useMediaQuery } from 'clubbi-ui'

import { useAppSelector } from '../../../common/hooks'
import { simuledPriceDrawerActions } from '../../../store/slices/simuledPriceDrawer'

import {
  StyledDrawer,
  StyledContainer,
  StyledHeader,
  StyledButton,
  StyledBackButton,
  StyledDiv,
  StyledItem,
} from './styles/SimuledPriceDrawer.style'
import { CartItem } from '../../../store/data/types'
import { CartListItemSimulation } from '../../organisms/Cart/CartListItemSimulation'
import { ButtonProfitabilities } from '../../atoms/ButtonProfitabilities'

export const SimuledPriceDrawer = ({ cartItem }: { cartItem: CartItem | null }) => {
  const { isOpen } = useAppSelector((state) => state.simuledPriceDrawer)
  const dispatch = useDispatch()

  const isMobile = useMediaQuery(themeClubbi.breakpoints.down('md'))

  const closeDrawer = () => {
    dispatch(simuledPriceDrawerActions.setIsOpen(false))
  }

  return (
    <StyledDrawer
      variant="temporary"
      anchor={isMobile ? 'bottom' : 'top'}
      open={isOpen}
      onClose={closeDrawer}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <StyledContainer>
        <StyledHeader>
          <StyledButton data-testid="close-drawer" onClick={closeDrawer}>
            <CloseIcon color={themeClubbi.palette.brandGrey.main} />
          </StyledButton>
        </StyledHeader>
        <StyledItem>
          {cartItem && <CartListItemSimulation item={cartItem} key={cartItem.product.id} />}
        </StyledItem>
        <StyledDiv>
          <ButtonProfitabilities />
          <StyledBackButton onClick={() => closeDrawer()}>
            Continuar para o carrinho
          </StyledBackButton>
        </StyledDiv>
      </StyledContainer>
    </StyledDrawer>
  )
}
