import { clubbiStyled } from 'clubbi-ui'
import { Link } from 'react-router-dom'
import { getSeasonStyle } from '../../../../common/seasonality'
import { isClubbiAnniversary } from '../../../../common/utils'

const season = getSeasonStyle()

export const StyledHeaderMain = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  width: '100%',
  padding: '11px',

  [theme.breakpoints.up('md')]: {
    padding: '8px 0px',
    justifyContent: 'space-around',
    gap: '14px',
  },
}))

export const StyledLogo = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

export const StyledImg = clubbiStyled('img')(({ theme }) => ({
  paddingBottom: '10px',
}))

export const StyledIconsHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginLeft: '10px',
  gap: '5px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '0px',
    gap: '20px',
  },
}))

export const StyledLinkHeader = clubbiStyled(Link)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '11px',
  marginLeft: '5px',
}))

export const StyledSearchBar = clubbiStyled('div')(({ theme }) => ({
  width: '100%',
  borderRadius: '100px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '11px',
    maxWidth: '578px',
  },
}))

export const StyledContainerIcons = clubbiStyled('div')<{ isLogged: boolean }>(({ isLogged }) => ({
  display: 'flex',
  width: '100%',
  height: '44px',
  justifyContent: isLogged ? 'space-between' : 'end',
  alignItems: 'center',
}))

export const StyledIconButton = clubbiStyled('div')<{
  isActive: boolean
}>(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  backgroundColor: isActive ? theme.palette.brandPurple[10] : 'transparent',
  borderRadius: '4px',
  cursor: 'pointer',
}))

export const StyledHeaderButton = clubbiStyled('div')<{
  isActive: boolean
  isDesktop: boolean
}>(({ isActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isActive ? season.hoverColor : 'transparent',
  borderRadius: '28px',
  padding: '10px 15px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: isClubbiAnniversary() ? 'white' : season.hoverColor,
  },
}))

export const StyledProfileButton = clubbiStyled('div')<{
  isActive: boolean
  isDesktop: boolean
}>(({ isActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isActive ? season.hoverColor : 'transparent',
  borderRadius: '28px',
  padding: '10px 15px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: isClubbiAnniversary() ? 'white' : season.hoverColor,
  },
}))

export const StyledShoppingListButton = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '28px',
  padding: '8px',
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'start',
    height: '44px',
    padding: '10px 15px',
  },
  '&:hover': {
    backgroundColor: isClubbiAnniversary() ? 'white' : season.hoverColor,
  },
}))
