import { anniversaryColor } from '../../../common/utils'

export const CakeIcon = () => {
  const color = anniversaryColor.red
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.00012 22.5001C3.71679 22.5001 3.47929 22.4042 3.28762 22.2126C3.09596 22.0209 3.00012 21.7834 3.00012 21.5001V16.5001C3.00012 15.9501 3.19596 15.4792 3.58762 15.0876C3.97929 14.6959 4.45012 14.5001 5.00012 14.5001V10.5001C5.00012 9.95007 5.19596 9.47924 5.58762 9.08757C5.97929 8.69591 6.45012 8.50007 7.00012 8.50007H11.0001V7.05007C10.7001 6.85007 10.4585 6.60841 10.2751 6.32507C10.0918 6.04174 10.0001 5.70007 10.0001 5.30007C10.0001 5.05007 10.0501 4.80424 10.1501 4.56257C10.2501 4.32091 10.4001 4.10007 10.6001 3.90007L11.6501 2.85007C11.6835 2.81674 11.8001 2.76674 12.0001 2.70007C12.0335 2.70007 12.1501 2.75007 12.3501 2.85007L13.4001 3.90007C13.6001 4.10007 13.7501 4.32091 13.8501 4.56257C13.9501 4.80424 14.0001 5.05007 14.0001 5.30007C14.0001 5.70007 13.9085 6.04174 13.7251 6.32507C13.5418 6.60841 13.3001 6.85007 13.0001 7.05007V8.50007H17.0001C17.5501 8.50007 18.021 8.69591 18.4126 9.08757C18.8043 9.47924 19.0001 9.95007 19.0001 10.5001V14.5001C19.5501 14.5001 20.021 14.6959 20.4126 15.0876C20.8043 15.4792 21.0001 15.9501 21.0001 16.5001V21.5001C21.0001 21.7834 20.9043 22.0209 20.7126 22.2126C20.521 22.4042 20.2835 22.5001 20.0001 22.5001H4.00012ZM7.00012 14.5001H17.0001V10.5001H7.00012V14.5001ZM5.00012 20.5001H19.0001V16.5001H5.00012V20.5001Z"
        fill={color}
      />
    </svg>
  )
}
