import { anniversaryColor, isClubbiAnniversary } from '../../../../common/utils'

export const ShoppingListIcon = () => {
  const color = isClubbiAnniversary() ? anniversaryColor.deepPurple : 'white'
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 2V13H10V22L17 10H13L16 2H7Z" fill={color} />
    </svg>
  )
}
