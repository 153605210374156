import React, { useEffect, useState } from 'react'
import { getCustomProducts } from '../../../services/productApi'
import { Product } from '../../../store/data/types'
import { MediaSizes } from '../../../common/mediaBreakpoints'
import { useMediaQuery } from 'usehooks-ts'
import { ProductCard } from '../product/ProductCard'
import { useAppSelector } from '../../../common/hooks'
import {
  StyledCard,
  StyledDiv,
  StyledMain,
  StyledMobile,
  StyledTitle,
} from './styles/ProductSuggestion.style'

export const ProductsSuggestions = (): React.ReactElement => {
  const [suggestions, setSuggestions] = useState<Product[]>([])
  const hasSuggestions = suggestions.length > 0

  const isDesktop = useMediaQuery(`(min-width: ${MediaSizes.ExtraLarge})`)
  const session = useAppSelector((state) => state.session)
  const { cartSessionId } = session

  const fetchSuggestions = async () => {
    const suggestedProducts = await getCustomProducts(10, cartSessionId)
    setSuggestions(suggestedProducts)
  }

  useEffect(() => {
    fetchSuggestions()
  }, [])

  return (
    <>
      {hasSuggestions && (
        <StyledMain>
          <StyledTitle>Mais produtos com preços imperdíveis</StyledTitle>
          {isDesktop ? (
            <StyledDiv>
              {suggestions.map((suggestion, index) => (
                <StyledCard key={index}>
                  <ProductCard product={suggestion} key={index} />
                </StyledCard>
              ))}
            </StyledDiv>
          ) : (
            <StyledMobile>
              {suggestions.map((suggestion, index) => (
                <ProductCard key={index} product={suggestion} data-testid="suggestion-item" />
              ))}
            </StyledMobile>
          )}
        </StyledMain>
      )}
    </>
  )
}
