import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import useHotjar from 'react-use-hotjar'
import { useLocation } from 'react-router-dom'
import { useInterval } from 'usehooks-ts'
import { ErrorBoundary } from 'react-error-boundary'
import { clarity } from 'react-microsoft-clarity'
import { SplitFactory } from '@splitsoftware/splitio-react'
import { clubbiStyled } from 'clubbi-ui'

import { Environment, getEnvironment } from '../../common/getEnv'
import { useAppDispatch, useAppSelector } from '../../common/hooks'
import { loginFromCode } from '../../store/slices/session'
import { LoginModal } from './LoginModal'
import { DeactivatedMerchantModal } from './DeactivatedMerchantModal'
import { DefaultingMerchantModal } from './DefaultingMerchantModal'
import { MINUTE_MS, REFRESH_TOKEN_INTERVAL } from '../../common/constants'
import { loginAgainIfNeeded } from '../../store/data/loginManager'
import { ErrorFallback } from '../../common/ErrorBoundary'

const StyledContainer = clubbiStyled('div')(() => ({
  width: '99.6vw',
}))

const usePageTracking = (): void => {
  const location = useLocation()
  const merchantCode = useAppSelector((state) => state.session.merchantCode)
  const [initialized, setInitialized] = useState(false)
  const { initHotjar } = useHotjar()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (getEnvironment() == Environment.Production) {
      initHotjar(2031826, 6, false, console.info)
    } else {
      if (getEnvironment() == Environment.Staging) {
        initHotjar(2316296, 6, false, console.info)
      }
    }
  }, [initHotjar])

  useEffect(() => loginAgainIfNeeded(dispatch), [dispatch])
  useInterval(loginAgainIfNeeded(dispatch), 5 * MINUTE_MS)

  useEffect(() => {
    if (getEnvironment() != Environment.Production) {
      // Exclude the pageview from both local and staging
      return
    }
  })
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])

  useEffect(() => {
    if (!initialized) {
      ReactGA.initialize('UA-193072576-1', {
        gaOptions: {
          userId: merchantCode || 'notLogged',
        },
      })
      setInitialized(true)
    } else {
      ReactGA.set({ userId: merchantCode || 'notLogged' })
    }
    ReactGA.pageview(location.pathname + location.search)
  }, [merchantCode])
}

const useLoginIfNeeded = (): void => {
  const dispatch = useAppDispatch()
  const [regionId, merchantCode] = useAppSelector((state) => [
    state.session.regionId,
    state.session.merchantCode,
  ])

  useEffect(() => {
    if (!regionId && merchantCode) {
      dispatch(loginFromCode({ merchantCode }))
    }
  }, [dispatch, regionId, merchantCode])
}

const useClarity = (): void => {
  const [merchantCode] = useAppSelector((state) => [state.session.merchantCode])

  const location = useLocation()

  useEffect(() => {
    clarity.identify('USER_ID', { userProperty: merchantCode })
    clarity.consent()
  }, [location])
}

const _PageTrackerWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
  usePageTracking()
  useClarity()
  return <>{children}</>
}

export const Middleware = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const dispatch = useAppDispatch()
  const { merchantCode } = useAppSelector((state) => state.session)
  useLoginIfNeeded()

  useEffect(() => {
    if (merchantCode) dispatch(loginFromCode({ merchantCode }))
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (merchantCode) dispatch(loginFromCode({ merchantCode }))
    }, REFRESH_TOKEN_INTERVAL)

    return () => clearInterval(intervalId)
  }, [merchantCode])

  const useSplitSdkConfig = () => {
    const sdkConfig: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey: process.env.REACT_APP_SPLIT_KEY!,
        key: merchantCode ?? 'notLogged',
      },
    }

    return sdkConfig
  }

  return (
    <SplitFactory config={useSplitSdkConfig()}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window && window.location.reload()}
      >
        <StyledContainer>
          <LoginModal />
          <DeactivatedMerchantModal />
          <DefaultingMerchantModal />
          <_PageTrackerWrapper>{children}</_PageTrackerWrapper>
        </StyledContainer>
      </ErrorBoundary>
    </SplitFactory>
  )
}
