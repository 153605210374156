import { StyledButton } from './styles/FinishPurchaseButton.style'
import { CartItem } from '../../../store/data/types'
import { useAppSelector } from '../../../common/hooks'
import { getPriceByMode } from '../../../common/productUtils'
import { isMerchantBlocked } from '../../../common/utils'
import { FinishPurchaseButtonProps } from './interfaces'
import gtm from '../../../common/gtmTracker'
import { WARNING_CUTOFF_END_TIME, WARNING_CUTOFF_START_TIME } from '../../../common/constants'

export const FinishPurchaseButton = ({
  loginUpdated,
  formValue,
  isLoading,
  submitOrder,
  showModal,
  setShowModal,
  setShowModalInstantPayment,
}: FinishPurchaseButtonProps) => {
  const { cart, session } = useAppSelector((state) => state)
  const { status, isInstantPayment } = session
  const cartItems: CartItem[] = useAppSelector((state) => {
    return Object.values(state.cart).filter(
      ({ quantity, isPackageMode, product }) =>
        quantity > 0 && getPriceByMode(isPackageMode, product)
    )
  })

  const getLabel = () => (isLoading ? 'Finalizando compra...' : 'Finalizar compra')
  const blockedCustomerStatus = isMerchantBlocked(status)

  const hasSimuledPrices = Object.entries(cart).some(
    ([, cartItem]) => cartItem.simuledPrice !== undefined
  )

  const handleClick = () => {
    const date = new Date()
    const hour = date.getHours()

    if (session?.merchantCode) {
      gtm.triggerCheckout(
        cartItems,
        formValue.paymentType,
        session.merchantCode,
        session.clubberEmail
      )
    }

    const showWarningCutoffModal = !showModal

    if (
      showWarningCutoffModal &&
      (hour >= WARNING_CUTOFF_START_TIME || hour <= WARNING_CUTOFF_END_TIME)
    ) {
      setShowModal(showWarningCutoffModal)
      return
    }
    if (isInstantPayment) {
      setShowModalInstantPayment(true)
      return
    }
    submitOrder()
  }

  const isButtonDisabled = () =>
    (cartItems.length <= 0 && loginUpdated) ||
    isLoading ||
    blockedCustomerStatus ||
    formValue.merchantCodeInvalid ||
    formValue.invoiceType === -1 ||
    formValue.paymentType === -1 ||
    hasSimuledPrices

  return (
    <StyledButton
      onClick={handleClick}
      typeButton="filled"
      size="medium"
      label={getLabel()}
      disabled={isButtonDisabled()}
    />
  )
}
