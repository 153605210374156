import { useAppSelector } from '../../../common/hooks'
import { CartItem } from '../../../store/data/types'
import { getTotalPrice } from '../../../common/cardUtils'
import { getPriceByMode } from '../../../common/productUtils'
import { Money } from '../../atoms'
import { getMissionsDiscount } from '../../../common/missions'
import { formatNumber, getShippingInfos } from '../../../common/utils'

import {
  CartCardContainer,
  CartDeliveryContainer,
  CartDeliveryWarningSpan,
  CartDetailDiv,
  CartDetailSpan,
  CartProfitabilities,
  CartProfitabilitiesSpan,
  CartTotalSpan,
  MissionsDiscountPrice,
  StyledDeliveryFree,
  StyledHR,
} from './styles/CartPurchaseInfoCard.style'
import { ButtonProfitabilities } from '../../atoms/ButtonProfitabilities'

export const CartPurchaseInfoCard = () => {
  const missions = useAppSelector((state) => state.missions)
  const { shippingType } = useAppSelector((state) => state.shippingInfos)
  const totalProfitabilities = useAppSelector((state) => state.profitabilities.total)
  const { cart } = useAppSelector((state) => state)
  const { clubberEmail } = useAppSelector((state) => state.session)

  const fee = getShippingInfos(shippingType).value
  const discountAmount = getMissionsDiscount(missions, cart, fee)

  const getCartItemPrice = (cartItem: CartItem) => {
    const { getMoreForLess } = cartItem.product.supplierPrices[0]
    if (getMoreForLess) {
      return +getTotalPrice(cartItem.product, 0, cartItem.quantity)
    }
    return cartItem.quantity * getPriceByMode(cartItem.isPackageMode, cartItem.product)!
  }

  const cartItems = Object.values(cart).filter((item) => item.quantity > 0)

  const totalPrice = cartItems.reduce(
    (x: number, cartItem: CartItem) => x + getCartItemPrice(cartItem),
    0
  )

  return (
    <CartCardContainer>
      <StyledHR />
      <CartDetailDiv>
        <CartDetailSpan>Subtotal</CartDetailSpan>
        <CartDetailSpan title="products-label" id="products-label">
          <Money amount={totalPrice} />
        </CartDetailSpan>
      </CartDetailDiv>
      {!!discountAmount && (
        <CartDetailDiv>
          <CartDetailSpan>Desconto das missões:</CartDetailSpan>
          <CartDetailSpan title="mission-discount-label" id="mission-discount-label">
            <MissionsDiscountPrice>
              -<Money amount={discountAmount} />
            </MissionsDiscountPrice>
          </CartDetailSpan>
        </CartDetailDiv>
      )}

      <CartDetailDiv>
        <CartDeliveryContainer>
          <CartDetailSpan isDelivery>Frete</CartDetailSpan>
          <CartDeliveryWarningSpan>
            (Taxa única para compras da mesma entrega)
          </CartDeliveryWarningSpan>
        </CartDeliveryContainer>
        <CartDetailSpan isDelivery title="delivery-label" id="delivery-label">
          {fee > 0 ? <Money amount={fee} /> : <StyledDeliveryFree>Grátis</StyledDeliveryFree>}
        </CartDetailSpan>
      </CartDetailDiv>
      <StyledHR />

      <CartDetailDiv>
        <CartTotalSpan hasProfitabilities={!!totalProfitabilities}>Total:</CartTotalSpan>
        <CartTotalSpan title="total-label" hasProfitabilities={!!totalProfitabilities}>
          <Money amount={totalPrice + fee - discountAmount} />
        </CartTotalSpan>
      </CartDetailDiv>

      {clubberEmail && totalProfitabilities && (
        <CartProfitabilities>
          <CartDetailDiv>
            <CartProfitabilitiesSpan>$/Kg</CartProfitabilitiesSpan>
            <CartProfitabilitiesSpan
              isNegative={totalProfitabilities.marginByKg < 0}
            >{`R$ ${formatNumber(totalProfitabilities.marginByKg)}/Kg`}</CartProfitabilitiesSpan>
          </CartDetailDiv>
          <CartDetailDiv>
            <CartProfitabilitiesSpan>LC1</CartProfitabilitiesSpan>
            <CartProfitabilitiesSpan isNegative={totalProfitabilities.lc1 < 0}>{`R$ ${formatNumber(
              totalProfitabilities.lc1
            )}`}</CartProfitabilitiesSpan>
          </CartDetailDiv>
          <CartDetailDiv>
            <CartProfitabilitiesSpan>LC1%</CartProfitabilitiesSpan>
            <CartProfitabilitiesSpan
              isNegative={totalProfitabilities.lc1Percent < 0}
            >{`${totalProfitabilities.lc1Percent}%`}</CartProfitabilitiesSpan>
          </CartDetailDiv>
        </CartProfitabilities>
      )}
      {clubberEmail && <ButtonProfitabilities />}
    </CartCardContainer>
  )
}
