import { clubbiStyled, Spinner } from 'clubbi-ui'

export const CartButtonDiv = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 0 8px 0',
  whiteSpace: 'nowrap',
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 600,
  height: '32px',
  padding: '4px 20px',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  width: '100%',

  color: theme.palette.brandWhite.main,
  background: theme.palette.brandPurple[60],

  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
}))

export const StyledSpinner = clubbiStyled(Spinner)(({ theme }) => ({
  width: '24px !important',
  height: '24px !important',

  color: theme.palette.brandWhite.main,
}))
