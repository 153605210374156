import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import { useAppSelector } from '../../../common/hooks'
import { getPriceByMode } from '../../../common/productUtils'
import { CartChange, CartItem, Product } from '../../../store/data/types'
import { cartActions } from '../../../store/slices/cart'
import { quantityToCompleteComboCalc } from '../../../common/utils'
import { ComboBadge } from '../../molecules/PopupGetMoreForLess/ComboBadge'
import { CartItemInput } from '../../molecules/productItem/CartItemInput'
import { RootState } from '../../../store/reducers'
import { DeleteCartItem } from '../../molecules/productItem/DeleteCartItem'
import { GetMoreForLessInformations } from '../../molecules/getMoreForLessInformations/GetMoreForLessInformations'
import { Money } from '../../atoms'

import {
  StyledButtonRemoveSimulation,
  StyledButtonSimulation,
  StyledImage,
  StyledPackagePrice,
  StyledPackageQuantity,
  StyledPriceContainer,
  StyledProductCard,
  StyledProductDescription,
  StyledProductDescriptionContainer,
  StyledProductDetailsContainer,
  StyledProductDetailsContent,
  StyledQtyPriceContainer,
  StyledSimulation,
  StyledTotalPrice,
  StyledTotalPriceContainer,
  StyledUnitPrice,
} from './styles/CartProductsListItem.style'
import { getTotalPrice } from '../../../common/cardUtils'
import { themeClubbi, useMediaQuery } from 'clubbi-ui'
import { simuledPriceDrawerActions } from '../../../store/slices/simuledPriceDrawer'
import { SimuledPriceDrawer } from '../../molecules/simuledPriceDrawer/SimuledPriceDrawer'
import { getProfitabilities } from '../../../services/profitabilityCalculatorApi'
import { ProductProfitabilities } from '../../molecules/ProductProfitabilities'

export interface CartProductsListItemProps {
  item: CartItem
  className?: string
}

export const selectProduct = createSelector(
  (state: RootState) => state.cart,
  (_: RootState, { product }: { product: Product }) => product.id,
  (cart, id) => cart[id]?.quantity || 0
)

export const ConnectedPrice = ({
  price,
  id,
  product,
}: {
  price: number
  id: string
  product: Product
}) => {
  const quantity = useAppSelector((state: RootState) => state.cart[id]?.quantity || 0)
  const totalPriceOutsidePromotion = price * quantity
  const totalPrice = +getTotalPrice(product, 0, quantity)
  const amount = product.supplierPrices[0].getMoreForLess ? totalPrice : totalPriceOutsidePromotion
  return <Money isBold amount={amount} />
}

export const CartProductsListItem = ({
  item,
  className = '',
  ...props
}: CartProductsListItemProps) => {
  const { isPackageMode, product } = item
  const price = getPriceByMode(isPackageMode, product)
  const { merchantCode, clubberEmail } = useAppSelector((state) => state.session)
  const isGetMoreForLessPromotion = product.supplierPrices[0].getMoreForLess
  const value = useSelector((state: RootState) => selectProduct(state, { product }))
  const { cart, session } = useAppSelector((state) => state)
  const { isOpen } = useAppSelector((state) => state.simuledPriceDrawer)
  const dispatch = useDispatch()
  const [profitabilities, setProfitabilities] = useState<any>({})
  const [selectedItem, setSelectedItem] = useState<CartItem | null>(null)
  const [clearPrice, setClearPrice] = useState(false)

  const isDesktop = useMediaQuery(themeClubbi.breakpoints.up('md'))

  const height = 50
  const imageURL = height > 120 ? product.imageUrls.image300Px : product.imageUrls.image120Px

  const onChange = useCallback(
    (change: CartChange) => {
      dispatch(
        cartActions.setQuantity({
          product,
          cartChange: change,
          merchantCode: merchantCode!,
          clubberEmail,
          section: 'cart',
        })
      )
    },
    [dispatch, product]
  )

  const quantityGetMoreForLess = !!quantityToCompleteComboCalc(item)

  const productsProfitabilities = useAppSelector((state) => state.profitabilities)

  useEffect(() => {
    if (productsProfitabilities.products) {
      const filteredProduct = productsProfitabilities.products.find(
        (product) => product[item.product.ean]
      )?.[item.product.ean]

      setProfitabilities(filteredProduct)
    }
  }, [productsProfitabilities.products, item.product.ean])

  useEffect(() => {
    if (!isOpen) {
      setSelectedItem(null)
    }
  }, [isOpen])

  const handleClickSimuledPrice = () => {
    setSelectedItem(item)
    dispatch(simuledPriceDrawerActions.setIsOpen(true))
  }

  const handleClearSimuledPrices = () => {
    dispatch(cartActions.clearSimuledPrice({ productId: item.product.id }))
    setClearPrice(true)
  }

  useEffect(() => {
    if (clearPrice) {
      getProfitabilities(cart, session).finally(() => {
        setClearPrice(false)
      })
    }
  }, [clearPrice, cart, session])

  const simuledPrice = item.simuledPrice

  return (
    <StyledProductCard>
      <StyledProductDetailsContainer quantityGetMoreForLess={quantityGetMoreForLess}>
        {quantityGetMoreForLess && <ComboBadge item={item} />}
        <StyledProductDetailsContent>
          <StyledImage
            loading="lazy"
            decoding="async"
            src={imageURL}
            alt=""
            width={height}
            height={height}
          />
          <StyledProductDescriptionContainer>
            <StyledProductDescription>{product.description}</StyledProductDescription>
            {isPackageMode && (
              <StyledPriceContainer>
                <StyledPackageQuantity>
                  Caixa c/ {product.packageNumberOfItems}
                </StyledPackageQuantity>
                <StyledUnitPrice>
                  {'('}
                  <Money
                    amount={
                      isPackageMode
                        ? (simuledPrice || price) / (product?.packageNumberOfItems ?? 1)
                        : simuledPrice || price
                    }
                  />
                  {'/un'}
                  {')'}
                </StyledUnitPrice>
              </StyledPriceContainer>
            )}
            <StyledPackagePrice>
              <Money amount={simuledPrice || price} />
            </StyledPackagePrice>
            {clubberEmail && (
              <StyledSimulation>
                <StyledButtonSimulation onClick={handleClickSimuledPrice}>
                  Simular preço
                </StyledButtonSimulation>
                {!!simuledPrice && (
                  <StyledButtonRemoveSimulation onClick={handleClearSimuledPrices}>
                    Remover Simulação
                  </StyledButtonRemoveSimulation>
                )}
              </StyledSimulation>
            )}
            {isGetMoreForLessPromotion && (
              <GetMoreForLessInformations product={product} quantity={value} />
            )}
          </StyledProductDescriptionContainer>
        </StyledProductDetailsContent>
      </StyledProductDetailsContainer>
      <StyledQtyPriceContainer quantityGetMoreForLess={quantityGetMoreForLess}>
        {!isDesktop && clubberEmail && profitabilities && (
          <ProductProfitabilities props={profitabilities} />
        )}
        <CartItemInput
          value={value}
          onChange={onChange}
          product={product}
          ctx={'cart'}
          isOfertao={!!product.inOfertao}
          isPackageMode={isPackageMode}
          variant="small"
        />
        <StyledTotalPriceContainer>
          <StyledTotalPrice>
            <ConnectedPrice price={simuledPrice || price!} id={product.id} product={product} />
          </StyledTotalPrice>
          {isDesktop && clubberEmail && profitabilities && (
            <ProductProfitabilities props={profitabilities} />
          )}
          {!isOpen && (
            <DeleteCartItem
              value={value}
              onChange={onChange}
              product={product}
              ctx={'cart'}
              isOfertao={!!product.inOfertao}
              isPackageMode={isPackageMode}
            />
          )}
        </StyledTotalPriceContainer>
      </StyledQtyPriceContainer>
      {selectedItem && isOpen && <SimuledPriceDrawer cartItem={selectedItem} />}
    </StyledProductCard>
  )
}
