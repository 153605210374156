import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  isOpen: boolean
}

export const simuledPriceDrawerSlice = createSlice({
  name: 'simuledPriceDrawer',
  initialState: {
    isOpen: false,
  },
  reducers: {
    setIsOpen: (_state, action: PayloadAction<boolean>): State => {
      return { isOpen: action.payload }
    },
  },
})

export const simuledPriceDrawerReducer = simuledPriceDrawerSlice.reducer
export const simuledPriceDrawerActions = simuledPriceDrawerSlice.actions
