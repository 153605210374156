import { Drawer, clubbiStyled } from 'clubbi-ui'

export const StyledDrawer = clubbiStyled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    height: '440px',
    backgroundColor: theme.palette.brandGrey[10],

    [theme.breakpoints.up('md')]: {
      width: '800px',
      height: '300px',
      position: 'fixed',
      top: '20%',
      left: 'calc(50% - 400px)',
    },
  },
}))

export const StyledContainer = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSizeXXS,
  fontWeight: '600',
  textDecoration: 'underline',
  border: 'none',
  padding: '8px',

  backgroundColor: theme.palette.brandGrey[10],

  '&:hover': {
    textDecoration: 'underline',
    border: 'none',

    backgroundColor: theme.palette.brandGrey[10],
  },
}))

export const StyledItem = clubbiStyled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.brandGrey[30]}`,
  padding: '40px 50px 20px',
  borderRadius: '4px',

  [theme.breakpoints.up('md')]: {
    padding: '25px',
  },
}))

export const StyledDiv = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  padding: '8px 0 8px 0',
  gap: '6px',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '20px',
    gap: '20px',
  },
}))

export const StyledBackButton = clubbiStyled('button')(({ theme }) => ({
  fontSize: theme.typography.fontSize2XS,
  fontWeight: 600,
  height: '32px',
  padding: '4px 20px',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  width: '100%',

  color: theme.palette.brandWhite.main,
  background: theme.palette.brandPurple[60],

  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
  },
}))

export const StyledProductSimulationCard = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '140px',
  transform: 'scale(1.2)',

  [theme.breakpoints.up('md')]: {
    minHeight: '110px',
    transform: 'scale(1)',
    maxWidth: '680px',
  },
}))
