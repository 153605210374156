import ReactGA from 'react-ga'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import gtm from '../../common/gtmTracker'
import { getProductMaxOrderLimit } from '../data/cartFunctions'
import { CartChange, CartItem, Mission, Product } from '../data/types'
import { OrderItem } from '../../components/organisms/Cart/interfaces'
import { notEnoughPackageInStock } from '../../common/productUtils'

const initialCartItem = (product: Product, section?: string): CartItem => ({
  quantity: 0,
  product,
  isPackageMode: !product.packageNumberOfItems || notEnoughPackageInStock(product) ? false : true,
  changes: [],
  storeId: product.supplierPrices[0].supplierId,
  url: window.location.pathname,
  corridor: section,
})

type State = { [key: string]: CartItem }

type QuantityPayload = {
  product: Product
  cartChange: CartChange
  merchantCode: string
  ctx?: string
  mission?: Mission
  section?: string
  corridor?: string
  clubberEmail?: string
}

type SetModePayload = {
  product: Product
  isPackageMode: boolean
}

type SimuledPricePayload = {
  productId: string
  simuledPrice: number
}

const getCartItemQuantity = (
  isPackageMode: boolean,
  cartQuantity: number,
  product: Product
): number => {
  const packageNumberOfItems = product.packageNumberOfItems || 1
  const maxLimit = getProductMaxOrderLimit(product)

  const unitQuantity = Math.min(
    isPackageMode ? packageNumberOfItems * cartQuantity : cartQuantity,
    maxLimit
  )

  return isPackageMode ? Math.floor(unitQuantity / packageNumberOfItems) : unitQuantity
}

const cartSlice = createSlice({
  name: 'cart',
  initialState: {} as State,
  reducers: {
    setMode: (state, action: PayloadAction<SetModePayload>): State => {
      const { product, isPackageMode } = action.payload

      const productId = product.id
      const productCart = productId in state ? state[productId] : initialCartItem(product)

      const quantity = getCartItemQuantity(isPackageMode, productCart.quantity, product)

      return {
        ...state,
        [productId]: {
          ...productCart,
          quantity: quantity,
          isPackageMode,
          isAvailable: true,
        },
      }
    },
    setQuantity: (state, action: PayloadAction<QuantityPayload>) => {
      const { product, cartChange, merchantCode, ctx, section, clubberEmail } = action.payload

      const productId = product.id
      const productCart = productId in state ? state[productId] : initialCartItem(product, section)
      const changes = productCart.changes || []

      let quantity = cartChange.quantity > 0 ? cartChange.quantity : 0

      quantity = getCartItemQuantity(productCart.isPackageMode, quantity, product)

      if (productCart.quantity == 0 && quantity > 0) {
        ReactGA.event({
          action: 'includeFirstProduct',
          category: 'cartChange',
          value: quantity,
        })
      }

      if (merchantCode && productCart.quantity < quantity) {
        gtm.triggerAddToCart(product, merchantCode, quantity, section, clubberEmail)

        if (ctx && ctx.includes('mission')) {
          gtm.triggerAddMissionItemsToCart(merchantCode, product, quantity, clubberEmail)
        }

        if (ctx && ctx.includes('banner')) {
          gtm.triggerAddBannerItemsToCart(merchantCode, product, quantity, ctx[1], clubberEmail)
        }
      } else if (merchantCode && productCart.quantity > quantity) {
        gtm.triggerRemoveFromCart(product, merchantCode, quantity, section, clubberEmail)
      }

      if (!quantity) {
        return Object.keys(state)
          .filter((key) => key !== productId)
          .reduce((acc, curr) => ({ ...acc, [curr]: state[curr] }), {})
      }

      return {
        ...state,
        [productId]: {
          ...productCart,
          quantity,
          changes: [
            ...changes,
            {
              ...cartChange,
              change_datetime: new Date().toISOString(),
              previous_quantity: productCart.quantity,
            },
          ],
          url: window.location.pathname,
          corridor: section,
        },
      }
    },
    cleanUp: (state, action: PayloadAction<undefined>) => {
      return {}
    },
    removeByEan: (state, action: PayloadAction<string[]>) => {
      const excludeEANs = action.payload
      return Object.fromEntries<CartItem>(
        Object.entries(state).filter(([_, item]) => !excludeEANs.includes(item.product.ean))
      )
    },
    updateProduct: (state, action: PayloadAction<Product[]>) => {
      const products = action.payload
      const cartByEan = Object.fromEntries(Object.values(state).map((s) => [s.product.ean, s]))
      const eans = products.map((p) => p.ean)

      const changes = Object.fromEntries<CartItem>(
        products.map((product) => [
          product.id,
          {
            ...cartByEan[product.ean],
            product,
            storeId: product.supplierPrices[0].supplierId,
          },
        ])
      )

      const newState = Object.fromEntries(
        Object.entries(state).filter(([_, item]) => !eans.includes(item.product.ean))
      )

      return { ...newState, ...changes }
    },
    updateQuantityAndMode: (state, action: PayloadAction<OrderItem>) => {
      const newCartItem = action.payload

      const cartItems = Object.values(state)
      const cartItemWillBeChanged = cartItems.find(({ product }) => product.ean === newCartItem.ean)
      const id = cartItemWillBeChanged?.product.id

      if (id)
        return {
          ...state,
          [id]: {
            ...state[id],
            quantity: newCartItem.quantity,
            isPackageMode: newCartItem.isPackageMode,
            storeId: newCartItem.storeId,
          },
        }
    },
    removeProductsWithoutQuantity: (state) => {
      const newState: State = {}

      Object.values(state).forEach((cartItem) => {
        const id = cartItem.product.id
        if (cartItem.quantity !== 0) {
          newState[id] = cartItem
        }
      })

      return newState
    },
    setSimuledPrice: (state, action: PayloadAction<SimuledPricePayload>) => {
      const { productId, simuledPrice } = action.payload
      const productCart = state[productId]

      if (productCart) {
        return {
          ...state,
          [productId]: {
            ...productCart,
            simuledPrice,
          },
        }
      }

      return state
    },
    clearAllSimuledPrice: (state) => {
      const newState = { ...state }
      Object.keys(newState).forEach((productId) => {
        newState[productId] = {
          ...newState[productId],
          simuledPrice: undefined,
        }
      })
      return newState
    },
    clearSimuledPrice: (state, action: PayloadAction<{ productId: string }>) => {
      const { productId } = action.payload
      return {
        ...state,
        [productId]: {
          ...state[productId],
          simuledPrice: undefined,
        },
      }
    },
  },
})

export const cartReducer = cartSlice.reducer
export const cartActions = cartSlice.actions
