import { ScrollButton, clubbiStyled as styled } from 'clubbi-ui'
import { Link } from '../../../atoms/Link'

export const ProductsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  background: theme.palette.brandGrey[10],
  margin: '0',
  width: 'inherit',
  boxSizing: 'border-box',
}))

export const StyledSectionWrapper = styled('section')(({ theme }) => ({
  height: '100%',
  width: '100%',
  paddingLeft: '18px',

  [theme.breakpoints.up('sm')]: {
    marginLeft: '24px',
    overflowX: 'hidden',
    paddingLeft: '10px',
  },

  '.List': {
    paddingLeft: '10px',
  },

  '.List::-webkit-scrollbar': {
    height: '5px',
  },

  '.List::-webkit-scrollbar-thumb': {
    background: theme.palette.brandGrey[40],
    borderRadius: '8px',
  },

  '.List::-webkit-scrollbar-track': {
    height: '1px',
    borderRadius: '8px',
    background: 'transparent',
  },

  '.List:hover::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.brandPurple[60],
  },

  [theme.breakpoints.up('sm')]: {
    marginLeft: '24px',

    '.List::-webkit-scrollbar': {
      display: 'none',
    },

    '.List::-webkit-scrollbar-thumb': {
      display: 'none',
    },

    '.List::-webkit-scrollbar-track': {
      display: 'none',
    },

    '.List:hover::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
}))

export const StyledTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '5px',
  paddingRight: '10px',

  [theme.breakpoints.up('md')]: {
    margin: '0 0 16px 0',
    padding: 0,
  },
}))

export const StyledTitle = styled('span')(({ theme }) => ({
  fontWeight: '600',
  fontSize: theme.typography.fontSizeM,

  color: theme.palette.brandGrey[100],

  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.fontSizeL,
    fontWeight: '600',
    lineHeight: '22px',
    margin: 0,
  },
}))

export const StyledIcon = styled('span')(({ theme }) => ({
  marginRight: '8px',

  [theme.breakpoints.up('md')]: {
    marginRight: '10px',
  },
}))

export const StyledLink = styled(Link)<{ promotionPage: boolean }>(({ theme, promotionPage }) => ({
  paddingRight: promotionPage ? '30px' : '0px',

  fontWeight: '600',
  textDecoration: 'none',
  fontSize: theme.typography.fontSizeXS,
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: theme.palette.brandGrey[100],

  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.brandGrey[100],
  },

  [theme.breakpoints.up('md')]: {
    paddingRight: '30px',
    marginRight: '26px',
  },
}))

export const StyledCorridor = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    margin: '0 0 48px 0',
    height: '438px',
  },
}))

export const StyledArrowButton = styled(ScrollButton)(({ theme, direction }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
    position: 'absolute',
    right: direction === 'right' && '45px',
    marginTop: direction === 'right' ? '-306px' : '180px',
    background: theme.palette.brandGrey[100],
  },
}))
