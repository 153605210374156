import { clubbiStyled } from 'clubbi-ui'

export const StyledMain = clubbiStyled('main')(({ theme }) => ({
  backgroundColor: 'white',
  maxWidth: '550px',
  margin: 'auto',
  padding: '20px',
  position: 'relative',
  top: '10%',
  transform: 'translateY(-10%)',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
}))

export const StyledHeader = clubbiStyled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const StyledTitle = clubbiStyled('h3')(({ theme }) => ({
  color: '#856404',
  margin: 0,
}))

export const StyledBody = clubbiStyled('div')(({ theme }) => ({
  marginTop: '20px',
}))

export const StyledLink = clubbiStyled('a')(({ theme }) => ({
  color: '#856404',
  textDecoration: 'underline',
}))

export const StyledButton = clubbiStyled('button')(({ theme }) => ({
  textDecoration: 'underline',
  border: 'none',

  background: 'none',
}))
