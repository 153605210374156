import { Cart, CartItem, ProfitabilitiesPayload } from '../store/data/types'
import { apiPost, loadBody } from './api'

import { dispatch } from '../store'
import { profitabilitiesActions } from '../store/slices/profitabilities'
import { Session } from '../store/slices/session'

export const getProfitabilities = async (cart: Cart, session: Session) => {
  const cartSimuled: Cart = Object.fromEntries(
    Object.entries(cart).map(([key, item]) => {
      if (item.simuledPrice) {
        const firstSupplier = item.product.supplierPrices[0]
        const newItem: CartItem = {
          ...item,
          product: {
            ...item.product,
            ...(item.isPackageMode
              ? { packagePrice: item.simuledPrice }
              : { price: item.simuledPrice }),
            supplierPrices: [
              {
                ...firstSupplier,
                ...(item.isPackageMode
                  ? { packagePrice: item.simuledPrice }
                  : { price: item.simuledPrice }),
              },
            ],
          },
        }
        return [key, newItem]
      } else {
        return [key, item]
      }
    })
  )

  const filteredCart = Object.fromEntries(
    Object.entries(cartSimuled).filter(
      ([, cartItem]) => cartItem.isPackageMode !== undefined && cartItem.quantity !== undefined
    )
  )

  const data = {
    cart: filteredCart,
    session: {
      merchantCode: session.merchantCode,
      geoRegionId: session.geoRegionId,
      pricingGroupId: session.websiteRegionId,
      clubberEmail: session.clubberEmail,
    },
  }
  const response = await apiPost('/profitability-calculator', { data }, false)
  switch (response.status) {
    case 200: {
      const body = await loadBody(response)
      dispatch(profitabilitiesActions.setProfitabilities(body as ProfitabilitiesPayload))
      break
    }
    default:
      return []
  }
}
